<template>
  <div>
    <!--Loading :go_loading="showLoading" :automatic="false" /-->
    <Head v-if="entity_data.id" :entity="entity_data" />
    <router-view
      v-if="entity_data"
      :key="$route.path"
      :profile="entity_data"
    ></router-view>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import Head from "./Head.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
//import Loading from "@/components/Loading.vue";

export default defineComponent({
  name: "PageEntity",
  components: {
    //Loading,
    Head,
  },
  setup() {
    const route = useRoute();
    const showLoading = ref(false);
    const entity_data = ref({});
    const store = useStore();
    const communicationsTable = computed(() => store.getters.Medias);

    const getEntity = async () => {
      store.commit("setLoadingStatus", true);
      showLoading.value = true;
      store.dispatch("addBodyClassName", "page-loading");
      store.commit("setLoading", true);
      ApiService.get(`/api/entities/${route.params.id}`).then(function (
        response
      ) {
        entity_data.value = response.data;
        store.commit("clearMedias");
        store.commit("clearContacts");
        response.data.contacts.forEach(function (value, key) {
          store.commit("setContacts", response.data.contacts);
          value.communications.forEach(function (valCom, valKey) {
            store.commit("createMedias", {
              media_id: valCom.media_id,
              value: valCom.value,
              remark: valCom.remark,
              keyContact: key,
              contact_id: valCom.contact_id,
            });
            if (
              communicationsTable.value.length ===
              response.data.communications.length
            ) {
              store.commit("setMediaSetup", 1);
            }
          });
        });
        let addressData = [];
        store.commit("restoreEData");
        const edata = [];
        response.data.economic_data.forEach(function (value, key) {
          const payment_days = [];
          value.payment_days.forEach(function (pd) {
            payment_days.push(pd.day);
          });
          const applies = [];
          value.applies_to.forEach(function (ap) {
            applies.push(ap.applies_to);
          });
          store.commit("addEData", {
            key: key + 1,
            id: value.id,
            type: value.type,
            office_id: value.office_id,
            payment_days: payment_days,
            applies: applies,
            payment_type_detail_id: value.payment_type_detail_id,
          });
          edata.push({
            key: key + 1,
            id: value.id,
            type: value.type,
            office_id: value.office_id,
            payment_days: payment_days,
            applies: value.applies,
            payment_type_detail_id: value.payment_type_detail_id,
          });
        });
        response.data.addresses.forEach(function (value) {
          addressData.push({
            id: value.id,
            street: value.street,
            number: value.number,
            floor_number: value.floor_number,
            door: value.door,
            location_id: value.location_id,
            zip_code: value.zip_code,
            latitude: value.latitude,
            longitude: value.longitude,
            address_types: value.address_types,
          });
        });
        store.commit("setAllOffices", response.data.offices);
        store.commit("setAddresses", addressData);
        store.commit("setEntityTypes", response.data.entity_types);
        store.commit("setOffices", response.data.offices);
        store.commit("setSectors", response.data.sectors);
        store.commit("setEntityData", response.data);
        store.commit("setSyncData", response.data.synchronize_entities);
        store.commit("setCommunications", response.data.communications);
        //store.commit("setContactSelected", response.data.contacts[0].id);
        store.dispatch("removeBodyClassName", "page-loading");
        store.commit("setLoading", false);
        showLoading.value = false;
        /*const ids = [];
        response.data.offices.forEach((x) => {
          ids.push(x.id);
        });
        ApiService.query("/api/offices/lists", {
          params: {
            per_page: 100,
            entities: ids,
          },
        }).then(function (data) {
          store.commit("setAllOffices", data.data.offices);
          store.commit("setAddresses", addressData);
          store.commit("setEntityTypes", response.data.entity_types);
          store.commit("setOffices", response.data.offices);
          store.commit("setSectors", response.data.sectors);
          store.commit("setEntityData", response.data);
          store.commit("setSyncData", response.data.synchronize_entities);
          store.commit("setCommunications", response.data.communications);
          //store.commit("setContactSelected", response.data.contacts[0].id);
          store.dispatch("removeBodyClassName", "page-loading");
          store.commit("setLoading", false);
          showLoading.value = false;
        });*/
        store.commit("setLoadingStatus", false);
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("entityUpdate", [
        { route: "/entities", label: "entities" },
      ]);
      store.commit("emptyOffices");
      getEntity();
    });

    return {
      entity_data,
      showLoading,
    };
  },
});
</script>
