
import { computed, defineComponent, onMounted, ref, toRef } from "vue";
import { useStore } from "vuex";
import moment from "moment/moment";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "KtAccount",
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const permisionsAssigned = ref(0);
    const permissions = toRef(props.entity, "permissions_list");
    const user = computed(() => store.getters.currentUser);
    const files = ref(0);

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };
    onMounted(() => {
      ApiService.get(`/api/action_files`).then(({ data }) => {
        files.value = data.filter((obj: any) => obj.file_type_id != null && obj.entity_id == route.params.id).length;
      });
    });
    return {
      permisionsAssigned,
      formatDate,
      sita_path: document.location.origin,
      storageHost: process.env.VUE_APP_API_URL,
      files,
    };
  },
});
